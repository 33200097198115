<script setup lang="ts">
import { useAuthStore } from "@/store/auth";
import type { Provider } from "@supabase/supabase-js";
import { OAUTH_METHOD } from "@/constants";

interface Props {
  showDivider?: boolean;
  memberCreate?: boolean;
  method?: OAUTH_METHOD;
}

const props = withDefaults(defineProps<Props>(), {
  showDivider: true,
});

const { withLoader, isLoading } = useLoading();
const authStore = useAuthStore();

const providers = [
  {
    name: "google",
    icon: "logos:google-icon",
  },
  {
    name: "facebook",
    icon: "logos:facebook",
  },
  {
    name: "linkedin_oidc",
    icon: "logos:linkedin-icon",
  },
];

const signInWithOAuth = withLoader(async (provider: Provider) => {
  const selectedAuthFunction = props.memberCreate ? authStore.createMemberWithOauth : authStore.signInWithOAuth;
  const { error } = await selectedAuthFunction(provider);
  if (error) throw createError({ message: error.message ?? error });
});
</script>

<template>
  <div class="w-full">
    <div v-if="showDivider" class="relative flex items-center my-5">
      <div class="flex-grow border-t border-light-gray"></div>
      <span class="mx-2 flex-shrink-0 bg-white text-gray-500 text-xs">Or continue with</span>
      <div class="flex-grow border-t border-light-gray"></div>
    </div>
    <div class="w-full flex items-center gap-x-2">
      <button
        v-for="(provider, index) in providers"
        :key="index"
        type="button"
        class="h-btn h-btn-outline w-full"
        @click="signInWithOAuth(provider.name)"
        :disabled="isLoading"
      >
        <div class="flex items-center justify-center gap-x-2 w-full">
          <Icon :name="provider.icon" class="h-5 w-5" />
        </div>
      </button>
    </div>
  </div>
</template>
